import ResponsiveImage from '@/components/ResponsiveImage'
import Link from 'next/link'
import { memo } from 'react'

const lineItemImgLoader = ({ src }) => {
  return `${src}?w=400`
}

const LineItemProductImage = ({ imageUrl, title, productUrl }) => {
  return (
    <Link className="order-item__image" href={productUrl}>
      <ResponsiveImage loader={lineItemImgLoader} src={imageUrl} alt={title} />
    </Link>
  )
}

export default memo(LineItemProductImage)
